import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import consts from '../../constant';

function SpTab() {

    const navigate = useNavigate()
    const [cross, setCross] = useState('')

    const [crossVal, setCrossVal] = useState('Cross')

    const loaction = useLocation()

    const path = loaction.pathname.split('/')[1]
    const pair = loaction.pathname.split('/')[2]
    

    useEffect(() => {
        if (cross === 'spot') {
            navigate(`/trade/${consts?.MainPair}`)
        } else if (cross === 'future') {
            navigate(`/future/${consts?.MainPair}`)
        } else if (cross === 'margin') {
            navigate(`/margin/${consts?.MainPair}`)
        }
    }, [cross])

    return (
        <div>

            <div class="mydict2 x2">
                {/* <div>
                    <label>
                        <input type="text" name="radio" value={'spot'} onChange={(e) => setCross(e.target.value)} checked={path === `/trade/${consts?.MainPair}` && true} />
                        <span>Spot</span>
                    </label>
                    <label>
                        <input type="text" name="radio" value={'future'} onChange={(e) => setCross(e.target.value)} checked={path === `/future/${consts?.MainPair}` && true} />
                        <span>Future</span>
                    </label>
                    <label>
                        <input type="text" name="radio" value={'margin'} onChange={(e) => setCross(e.target.value)} checked={path === `/margin/${consts?.MainPair}` && true} />
                        <span>Margin</span>
                    </label>

                </div> */}
                <div>
                    <div onClick={(() => { setCross('spot') })} className={path !== `trade` ? 'def-tab' : 'def-tab active'}>
                        Spot
                    </div>
                    <div onClick={(() => { setCross('future') })} className={path !== `future` ? 'def-tab' : 'def-tab active'}>
                        Future
                    </div>
                    <div onClick={(() => { setCross('margin') })} className={path !== `margin` ? 'def-tab' : 'def-tab active'}>
                        Margin
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SpTab
