import React, { useEffect, useState } from 'react'
import './Trade.scss'
// import React, { useState } from 'react';
import {
    Modal,
    Box,
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    IconButton,
    InputAdornment,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import usercalls from "../../Axios";
import consts from '../../constant';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    '@media(max-width:767.98px)': {
        width: '80%',
    },
    maxWidth: 400,
    bgcolor: 'var(--mdl)',
    borderRadius: 2,
    boxShadow: 24,
    p: 3,
};
function Transfer() {
    const location = useLocation();
    var paths = location?.pathname?.split("/")[1];
    const path = usercalls();
    const [fromAccount, setFromAccount] = useState('');
    const [toAccount, setToAccount] = useState('');
    const [amount, setAmount] = useState(0);
    const [open, setOpen] = useState(false);
    const [allPairs, setAllPairs] = useState([]);
    const [pairBalance, setPairBalance] = useState(0);
    const [fromType, setFromType] = useState("");
    const [toType, setToType] = useState("");
    const [load, setLoad] = useState(false);

    const handleSwapAccounts = () => {
        const temp = fromAccount;
        setFromAccount(toAccount);
        setToAccount(temp);
    };

    const handleOnclose = () => {
        setOpen(false)
        setToType("")
        setFromType("")
        setToAccount("")
        setPairBalance(0)
        setAmount(0);
    };

    const gettAllcoins = async () => {

        try {

            const url = `${consts.BackendUrl}/getWallets`
            const response = await path.postCallTokenonly({ url });
            const result = await response.json();

            if (result.success) {
                setAllPairs(result?.result);
            }


        } catch (error) {
            console.log(error, "error getting all coins");
        }

    };

    useEffect(() => {
        gettAllcoins();
    }, []);

    const handleCoinChange = async (item) => {

        try {

            console.log(paths, "pathspaths");
            if (paths == 'trade') {
                setPairBalance(item?.balance);
            } else if (paths == "margin") {
                setPairBalance(item?.marginBalance);
            } else {
                setPairBalance(item?.futureBalance);
            }
            // console.log(item,"itemitem");
            setFromAccount(item?.coinname);

        } catch (error) {
            console.log(error, "error getting all coins");
        }

    };
    const [fromTypeError, setFromTypeError] = useState("");
    const [toTypeError, setToTypeError] = useState("");
    const [amountError, setAmountError] = useState("");
    const [toAccountError, setToAccountError] = useState("");

    const handleAssetTransfer = async () => {
        if (fromType == "") {
            setFromTypeError("Please Select From Wallet");
        } else if (toType == "") {
            setToTypeError("Please Select To Wallet");
        } else if (toAccount == "") {
            setToAccountError("Please Select Asset");
        } else if (amount <= 0) {
            setAmountError("Please Enter Valid Amount");
        } else {
            try {
                setLoad(true);
                const url = `${consts.BackendUrl}/binanceTrade/transferasset`
                const payload = {
                    type: `${fromType}_${toType}`, //"MAIN_MARGIN",
                    asset: toAccount,
                    amount: amount,
                    fromSymbol: "",
                    toSymbol: ""
                }
                const response = await path.postCall({ url, payload });
                const result = await response.json();

                if (result.success) {
                    toast.success(result?.message);
                    setFromAccount("");
                    setToAccount("");
                    setAmount("");
                    setFromType("");
                    setToType("");
                } else {
                    toast.error(result?.message);
                    setFromAccount("");
                    setToAccount("");
                    setAmount("");
                    setFromType("");
                    setToType("");
                }


            } catch (error) {
                console.log(error, "error getting all coins");
            } finally {
                setOpen(false);
                setLoad(false);
            }
        }

    };

    return (
        <div>
            {/* <ToastContainer /> */}
            <div className='transfer-btn' onClick={() => setOpen(true)}>
                Transfer
            </div>

            <Modal open={open} onClose={handleOnclose}>
                <Box sx={modalStyle}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600, color: 'var(--clr-font)' }}>
                            Transfer
                        </Typography>
                        <IconButton onClick={handleOnclose} size="small">
                            <HighlightOffIcon size={20} sx={{ fill: 'var(--clr-font)' }} />
                        </IconButton>
                    </Box>
                    <div>
                        <InputLabel>From</InputLabel>
                    </div>
                    <FormControl fullWidth sx={{ mb: 2 }}>

                        <Select
                            value={fromType}
                            // label="From"
                            onChange={(e) => { setFromType(e.target.value); setFromTypeError("") }}
                            sx={{ bgcolor: 'var(--clr-grey)', fieldset: { border: 'none' }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                        >
                            <MenuItem value="MAIN">Fiat & Spot</MenuItem>
                            <MenuItem value="MARGIN">Cross Margin</MenuItem>
                            {/* <MenuItem value="ISOLATEDMARGIN">Isolated Margin</MenuItem> */}
                            <MenuItem value="UMFUTURE">USDⓈ-M Futures</MenuItem>
                            <MenuItem value="CMFUTURE">COIN-M Futures</MenuItem>
                            <MenuItem value="FUNDING">Funding</MenuItem>
                        </Select>
                    </FormControl>
                    <p style={{ color: "red", textAlign: "center" }}>{fromTypeError != "" ? fromTypeError : null}</p>
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                        <IconButton
                            onClick={handleSwapAccounts}
                            sx={{
                                // bgcolor: '#f0f2f5',
                                // '&:hover': { bgcolor: '#e6e8eb' }
                            }}
                        >
                            <ImportExportIcon size={20} sx={{ fill: 'var(--clr-font)' }} />
                        </IconButton>
                    </Box>
                    <div>
                        <InputLabel>To</InputLabel>
                    </div>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        {/* <InputLabel>To</InputLabel> */}
                        <Select
                            value={toType}
                            // label="To"
                            onChange={(e) => { setToType(e.target.value); setToTypeError("") }}
                            sx={{ bgcolor: 'var(--clr-grey)', fieldset: { border: 'none' }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                        >
                            <MenuItem value="MAIN">Flat & Spot</MenuItem>
                            <MenuItem value="MARGIN">Cross Margin</MenuItem>
                            {/* <MenuItem value="ISOLATEDMARGIN">Isolated Margin</MenuItem> */}
                            <MenuItem value="UMFUTURE">USDⓈ-M Futures</MenuItem>
                            <MenuItem value="CMFUTURE">COIN-M Futures</MenuItem>
                            <MenuItem value="FUNDING">Funding</MenuItem>
                        </Select>
                    </FormControl>
                    <p style={{ color: "red", textAlign: "center" }}>{toTypeError != "" ? toTypeError : null}</p>
                    <div>
                        <InputLabel>Coin</InputLabel>
                    </div>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        {/* <InputLabel>To</InputLabel> */}
                        <Select
                            value={toAccount}
                            // label="To"
                            onChange={(e) => { setToAccount(e.target.value); setAmount(0); setToAccountError("") }}
                            sx={{ bgcolor: 'var(--clr-grey)', fieldset: { border: 'none' }, color: 'var(--clr-font)', svg: { fill: 'var(--clr-font)' } }}
                        >
                            {toType != "UMFUTURE" ? allPairs?.map((item, index) => (
                                <MenuItem onClick={() => handleCoinChange(item)} key={index} value={item?.coinname}>
                                    <img style={{ width: "30px", height: "30px", marginRight: "10px" }} src={item?.asset_id?.imageurl} alt={item?.coinname} />
                                    {item?.coinname}
                                </MenuItem>
                            )) :

                                allPairs?.filter(item => item?.coinname == 'USDT')?.map((item, index) => (
                                    <MenuItem onClick={() => handleCoinChange(item)} key={index} value={item?.coinname}>
                                        <img style={{ width: "30px", height: "30px", marginRight: "10px" }} src={item?.asset_id?.imageurl} alt={item?.coinname} />
                                        {item?.coinname} </MenuItem>
                                ))

                            }

                            {/* <MenuItem value="funding">BTC</MenuItem>
                            <MenuItem value="margin">ETH</MenuItem> */}
                        </Select>
                    </FormControl>
                    <p style={{ color: "red", textAlign: "center" }}>{toAccountError != "" ? toAccountError : null}</p>
                    <div>
                        <InputLabel>Amount</InputLabel>
                    </div>
                    <TextField
                        fullWidth
                        // label="Amount"
                        value={amount}
                        onChange={(e) => { setAmount(e.target.value); setAmountError("") }}
                        type="number"
                        sx={{ mb: 2, bgcolor: 'var(--clr-grey)', fieldset: { border: 'none' }, input: { color: 'var(--clr-font)' } }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        onClick={(e) => { setAmount(pairBalance); setAmountError("") }}
                                        size="small"
                                        sx={{
                                            minWidth: 'auto',
                                            px: 1,
                                            color: 'var(--clr-font)',
                                            bgcolor: 'transparent',
                                            textTransform: 'capitalize',
                                            // texttransform: 'capitalise !important'
                                        }}
                                    >
                                        Max
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <p style={{ color: "red", textAlign: "center" }}>{amountError != "" ? amountError : null}</p>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 3,
                        color: 'var(--clr-font)',
                        fontSize: '0.875rem'
                    }}>
                        {/* <Info size={16} /> */}
                        <Typography variant="body2">
                            Available: {Number(pairBalance).toFixed(4)} {toAccount}
                        </Typography>
                    </Box>

                    <Button
                        disabled={load}
                        fullWidth
                        variant="contained"
                        sx={{
                            py: 0.8,
                            bgcolor: '#ff7028 !important',
                            color: '#fff',
                            textTransform: 'capitalize',

                        }}
                        onClick={handleAssetTransfer}
                    >
                        Transfer
                    </Button>
                </Box>
            </Modal>
        </div >
    )
}

export default Transfer
