import React, { useState, useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import Header from '../Header/Header'
// import ApexChart from './Apexchart'
import Tradeview from './Tradeview';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './Trade.scss'
import OrderBook from './OrderBook';
import { useTheme, useMediaQuery } from '@mui/material';
import Placeorder from './Placeorder';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import usercalls from "../../Axios";
import nodata from '../../Img/thankyou.gif'
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Btcoin from '../../Img/btcoin.png'
import { Drop, Drop2, Info, M1, M2, M3, Msg, Trading, Wifi } from '../../SvgCollection';
import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { useParams, useLocation } from 'react-router-dom';
import consts from '../../constant';
import { toast } from 'react-toastify';
import io from "socket.io-client";
// import FormHelperText from '@mui/material/FormHelperText';
// import FormControl from '@mui/material/FormControl';

const style = {
    position: 'absolute',
    top: '50%',
    left: '35%',  // Start from the left side of the screen
    transform: 'translateY(-50%)', // Center vertically
    width: 320,
    bgcolor: 'var(--box-bg)',
    // border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '12px',
    pt: 2,
    px: 4,
    pb: 3,
};



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const socketInstance = io.connect(consts.socketUrl);

function TradeHistory({ historyStatus, setBalanceStatus, markPrice }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [cross, setCross] = useState('CROSSED')
    const [posSide, setPosSide] = useState('SELL')

    const [crossVal, setCrossVal] = useState('LIMIT')

    const loaction = useLocation();
    const placeRoute = loaction.pathname?.split('/')[1];

    const [value, setValue] = useState(0);
    const path = usercalls()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [orderType, setOrderType] = useState("open")


    const { id } = useParams()
    const [value5, setValue5] = useState(0);

    const handleChange5 = (event, newValue) => {
        var data = ["open", 'history']
        setOrderType(data[newValue])
        setValue5(newValue);
        console.log(newValue, 'newvalue');

    };

    const [value6, setValue6] = useState(0);

    const handleChange6 = (event, newValue) => {
        setValue6(newValue);
    };

    const [value7, setValue7] = useState(0);

    const handleChange7 = (event, newValue) => {
        setValue7(newValue);
    };

    const [age, setAge] = useState(10);

    const [dropDown, setDropDown] = useState(false)

    const handleChangeAge = (event) => {
        setAge(event.target.value);
    };

    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

    const mobilemd = useMediaQuery(theme.breakpoints.up('md'));
    /*drawer */
    const [bysel, setBysel] = useState(null);

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open, content) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
        setBysel(content)
    };
    const [orderHistory, setOrderHistory] = useState([])
    const [positionHistory, setPositionHistory] = useState([])

    const [orderCount, setOrderCount] = useState(0)

    const [connect, setConnect] = useState(false)

    const getHistory = async () => {
        try {
            setOrderHistory([])

            const url = `${consts.BackendUrl}/binanceTrade/orderHistory`

            const payload = {
                pair: id?.split("_")?.join(""),
                // orderType: placeRoute == 'future' ? "FUTURE" : placeRoute == 'margin' ? "MARGIN" : orderType,
                orderType: placeRoute == 'trade' ? "spot" : placeRoute,
                isHistory: value5 == 1 ? "true" : "false"
            }

            // console.log(payload, 'payloada');
            const response = await path.postCall({ url, payload });
            const result = await response.json();
            if (result?.success) {
                var ids
                if (orderType == "history") {
                    console.log(result?.result, "result?.result")
                    setOrderHistory(result?.result?.slice(0, 5))
                    setOrderCount(result?.orderCount ? result?.orderCount : 0)
                    ids = result?.result?.length > 0 ? result?.result[0]?.user_id?.toString() : ""
                } else {
                    setOrderCount(result?.result ? result?.result?.length : 0)
                    setOrderHistory(result?.result)
                    ids = result?.result?.length > 0 ? result?.result[0]?.user_id?.toString() : ""
                }
                console.log(ids, "ids")
                if (connect == false && ids != "") {
                    socketInstance.emit('join', {
                        room: ids
                    })
                    setConnect(true)
                }
            }
        } catch (error) {
            console.log("🚀 ~ getHistory ~ error:", error)
        }
    }

    const [socData, setSocData] = useState("")

    useEffect(() => {
        if (socData) {
            if (orderType != "history") {
                setOrderHistory(prevOrders =>
                    prevOrders.filter(order => order._id !== socData?._id)
                );
                setOrderCount(orderCount - 1)
            }
        }
    }, [socData])

    useEffect(() => {
        socketInstance.on('open_order', (data) => {
            console.log(data, "data")
            if (data?.status == "FILLED") {
                setSocData(data)
            }
        })
    }, [socketInstance])

    useEffect(() => {
        if (id && orderType) {
            getHistory()
        }
    }, [id, orderType, historyStatus, placeRoute])

    const cancelOrder = async (orderId) => {
        try {
            var url;
            if (placeRoute == "trade") {
                url = `${consts.BackendUrl}/binanceTrade/cancelOrder`
            } else if (placeRoute == "future") {
                url = `${consts.BackendUrl}/binanceTrade/future/cancelOrder`
            } else {
                url = `${consts.BackendUrl}/binanceTrade/margin/cancelmarginorder`
            }

            const payload = {
                orderId: orderId
            }
            const response = await path.postCall({ url, payload });
            const result = await response.json();
            if (result?.success == true) {
                toast.success(result?.message)
                getHistory()
                setBalanceStatus(Math.floor(Math.random() * 90) + 10)
            } else {
                toast.error(result?.message)
            }
        } catch (error) {
            console.log("🚀 ~ cancelOrder ~ error:", error)
        }
    }

    const getPositions = async () => {

        try {

            const url = `${consts.BackendUrl}/binanceTrade/future/openOrders`;

            const payload = {
                symbol: id
            }

            const response = await path.postCall({ url, payload });
            const result = await response.json();

            // console.log(result, "positttiiiiooonnn");



            if (result?.success == true) {
                setPositionHistory(result?.result)
            } else {
                setPositionHistory([]);
                toast.error(result?.message)
            }

        } catch (error) {
            console.log("POsition error", error);
        }

    };

    const updateTrade = async (id, status) => {

        try {

            const url = `${consts.BackendUrl}/binanceTrade/updatetradee`;

            const payload = {
                _id: id,
                status: status
            }

            const response = await path.postCall({ url, payload });
            const result = await response.json();


            if (result?.success == true) {
                getHistory();
            }

        } catch (error) {
            console.log(error, "Error Updating Trade Details");
        }

    };
    var ws;
    const getListenKey = async () => {

        try {

            const url = `${consts?.BackendUrl}/binanceTrade/future/getlistenkey`;
            const payload = {
                type: placeRoute
            };

            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success == true) {
                // console.log(result?.result, "listenKey");
                const listenKey = result?.result;
                
                if (placeRoute == "future") {
                    ws = new WebSocket(`wss://fstream.binance.com/ws/${listenKey}`);
                } else {
                    ws = new WebSocket(`wss://stream.binance.com:9443/ws/${listenKey}`);
                }
                // const ws = new WebSocket(`wss://stream.binancefuture.com/ws/${listenKey}`);
                // const ws = new WebSocket(`wss://stream.binance.com:9443/ws/${listenKey}`);


                ws.onopen = (event) => {
                    console.log(`Connected to Binance WebSocket for ${result?.type}`);
                };

                ws.onmessage = function (event) {
                    try {
                        const message = JSON.parse(event.data);
                        // console.log('Position Data:', message);

                        if ((message?.e == "executionReport") && (placeRoute != "future")) {
                            console.log('PositionData:', message);
                            // if (message?.X != "NEW") {
                            // const matchedItem = orderHistory.find(item => item?.others?.orderId == message?.i)
                            setOrderHistory(prevState => prevState.filter(item => item?.others?.orderId != message?.i))
                            updateTrade(message?.i, message?.X);
                            // }
                        } else if ((message?.e == "ORDER_TRADE_UPDATE") && (placeRoute == "future")) {
                            setOrderHistory(prevState => prevState.filter(item => item?.others?.orderId != message?.o?.i))
                            updateTrade(message?.o?.i, message?.o?.X);
                        }

                    } catch (error) {
                        console.error('Error parsing WebSocket message:', error, event.data);
                    }
                };

                ws.onerror = (error) => {
                    console.error('WebSocket Error:', error);
                };

                ws.onclose = () => {
                    console.log('WebSocket Connection Closed');
                };

            }

        } catch (error) {
            console.log(error, "error getting listen key");
        }

    };

    useEffect(() => {
        getListenKey();

        return () => {
            console.log('Cleaning up WebSocket connection');
            ws?.close();
        };

    }, [placeRoute]);

    useEffect(() => {

        if (value5 == 2) {
            getPositions();
        }

        // getListenKey();
    }, [value5]);

    const [posData, setPosData] = useState({});
    const [load, setLoad] = useState(false);

    const handleClosePosition = async () => {
        // console.log(posData, "posDataposData");

        try {
            setLoad(true);

            const url = `${consts.BackendUrl}/binanceTrade/future/tradeClosePosition`;

            const payload = {
                ccy: posData?.symbol,
                side: posData?.positionSide == "LONG" ? "SELL" : "BUY",
                positionSide: posData?.positionSide,
                type: posSide,
                quantity: posData?.positionAmt,
                price: posSide == "LIMIT" ? posData?.entryPrice : "",
                timeInForce: posSide === "LIMIT" ? "GTC" : ""
            }

            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success == true) {
                toast.success(result?.message)
                getPositions();
            } else {
                toast.error(result?.message);
            }


        } catch (error) {
            console.log(error, "error in Closing Position");
        } finally {
            setLoad(false);
            setOpen(false);
        }

    };

    const HandlePositionData = async (row) => {
        setPosData(row);

        setTimeout(() => {
            setOpen(true);
        }, 500);


    };

    return (
        <div className='Tradepage'>
            <Grid container sx={{ marginBottom: !mobilemd && '80px' }}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} order={!isLgUp ? 4 : 4}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ margin: "10px 12px" }}>
                            <Tabs value={value5} onChange={handleChange5} aria-label="basic tabs example" className='tabs-trade'>
                                <Tab label={`Open Orders (${orderCount})`} {...a11yProps(0)} />
                                <Tab label="Order History" {...a11yProps(1)} />
                                <Tab style={{ display: placeRoute == "trade" ? "none" : placeRoute == "margin" ? "none" : "" }} label="Positions" {...a11yProps(2)} />
                                {/* <Tab label="Trade History" {...a11yProps(2)} />
                                <Tab label=" Positions" {...a11yProps(3)} />
                                <Tab label=" Trading Algorithm" {...a11yProps(4)} />
                                <Tab label=" Realized PNL" {...a11yProps(5)} /> */}
                            </Tabs>
                        </Box>
                        {/* <CustomTabPanel >
                        <TableContainer >
                            <Table aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='left'>Date & Time</TableCell>
                                        <TableCell align='center'>Pair</TableCell>
                                        <TableCell align='center'>Type</TableCell>
                                        <TableCell align='center'>Side</TableCell>
                                        <TableCell align='center'>Price</TableCell>
                                        <TableCell align='center'>Quantity</TableCell>
                                        <TableCell align='center'>Total</TableCell>
                                        <TableCell align='center'>Status</TableCell>
                                        {
                                            orderType == "open" &&
                                            <TableCell align='center'>Cancel</TableCell>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        orderHistory?.length > 0 ? orderHistory?.map((row, index) => {
                                            return (
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    style={{ cursor: 'pointer' }}
                                                    key={index}
                                                >
                                                    <TableCell component="th" scope="row" align='left' >
                                                        {row?.createdAt?.split("T")[0]}: {row?.createdAt?.split("T")[1].split(".")[0]}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        {row?.ccy}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center'>
                                                        {row?.type}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {row?.side}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {row?.price}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {row?.quantity}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {(Number(row?.price) * Number(row?.quantity)).toFixed(4)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" align='center' >
                                                        {row?.status}
                                                    </TableCell>
                                                    {
                                                        orderType == "open" &&
                                                        <TableCell component="th" scope="row" align='center' >
                                                            <Button onClick={() => { cancelOrder(row?.orderId) }} >Cancel</Button>
                                                        </TableCell>
                                                    }
                                                </TableRow>
                                            )
                                        })
                                            :
                                            <TableRow>
                                                <TableCell align='center' colSpan={10}>
                                                    <div className='nodata'>
                                                        <img width="100" height="100" src="https://img.icons8.com/fluency/100/exchange-money-ethereum.png" alt="exchange-money-ethereum" />
                                                        No Record Found
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CustomTabPanel> */}


                        {value5 === 2 ?
                            <CustomTabPanel >
                                <TableContainer className='trade-hist'>
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>Date</TableCell>
                                                <TableCell align='center'>Pair</TableCell>
                                                <TableCell align='center'>Break Even Price</TableCell>
                                                <TableCell align='center'>Side</TableCell>
                                                <TableCell align='center'>Entry Price</TableCell>
                                                <TableCell align='center'>Quantity</TableCell>
                                                <TableCell align='center'>Liquidation Price</TableCell>
                                                <TableCell align='center'>PNL</TableCell>
                                                <TableCell align='center'>Close</TableCell>
                                                {/* <TableCell align='center'>Status</TableCell> */}
                                                {/* {
                                             orderType == "open" &&
                                             <TableCell align='center'>Cancel</TableCell>
                                         } */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* {console.log(positionHistory,"positionHistory")} */}
                                            {
                                                positionHistory?.length > 0 ? positionHistory?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            style={{ cursor: 'pointer' }}
                                                            key={index}
                                                        >
                                                            <TableCell component="th" scope="row" align='left' >
                                                                {row?.updateTime ? `${String(new Date(row?.updateTime).getDate()).padStart(2, '0')}/${String(new Date(row?.updateTime).getMonth() + 1).padStart(2, '0')}/${new Date(row?.updateTime).getFullYear()}` : "-"}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center'>
                                                                {row?.symbol}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center'>
                                                                {Number(row?.breakEvenPrice).toFixed(4)}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.positionSide}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.entryPrice}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.positionAmt}
                                                            </TableCell>
                                                            {/* <TableCell component="th" scope="row" align='center' >
                                                         {(Number(row?.price) * Number(row?.quantity)).toFixed(4)}
                                                     </TableCell>
                                                     <TableCell component="th" scope="row" align='center' >
                                                         {row?.status}
                                                     </TableCell> */}
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.liquidationPrice}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' style={{ color: (Number(markPrice) - Number(row?.entryPrice)) * Number(row?.positionAmt) > 0 ? "green" : "red" }}>
                                                                {((Number(markPrice) - Number(row?.entryPrice)) * Number(row?.positionAmt)).toFixed(2)}
                                                            </TableCell>
                                                            {

                                                                <TableCell component="th" scope="row" align='center' >
                                                                    <div className="cncel">
                                                                        <Button onClick={() => { HandlePositionData(row) }} >Cancel</Button>
                                                                    </div>

                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                                    :
                                                    <TableRow>
                                                        <TableCell align='center' colSpan={10}>
                                                            <div className='nodata'>
                                                                {/* <img width="100" height="100" src="https://img.icons8.com/fluency/100/exchange-money-ethereum.png" alt="exchange-money-ethereum" /> */}
                                                                No Record Found
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CustomTabPanel>

                            :
                            <CustomTabPanel >
                                <TableContainer >
                                    <Table aria-label="simple table" stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align='left'>Date & Time</TableCell>
                                                <TableCell align='center'>Pair</TableCell>
                                                <TableCell align='center'>Type</TableCell>
                                                <TableCell align='center'>Side</TableCell>
                                                <TableCell align='center'>Price</TableCell>
                                                <TableCell align='center'>Quantity</TableCell>
                                                <TableCell align='center'>Total</TableCell>
                                                <TableCell align='center'>Status</TableCell>
                                                {
                                                    orderType == "open" &&
                                                    <TableCell align='center'>Cancel</TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                orderHistory?.length > 0 ? orderHistory?.map((row, index) => {
                                                    return (
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            style={{ cursor: 'pointer' }}
                                                            key={index}
                                                        >
                                                            <TableCell component="th" scope="row" align='left' >
                                                                {row?.createdAt?.split("T")[0]}: {row?.createdAt?.split("T")[1].split(".")[0]}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center'>
                                                                {row?.ccy}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center'>
                                                                {row?.type}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.side}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.price}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.quantity}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {(Number(row?.price) * Number(row?.quantity)).toFixed(4)}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" align='center' >
                                                                {row?.status}
                                                            </TableCell>
                                                            {
                                                                orderType == "open" &&
                                                                <TableCell component="th" scope="row" align='center' >
                                                                    <div className="cncel">
                                                                        <Button onClick={() => { cancelOrder(row?.orderId) }} >Cancel</Button>
                                                                    </div>

                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                                    :
                                                    <TableRow>
                                                        <TableCell align='center' colSpan={10}>
                                                            <div className='nodata'>
                                                                {/* <img width="100" height="100" src="https://img.icons8.com/fluency/100/exchange-money-ethereum.png" alt="exchange-money-ethereum" /> */}
                                                                No Record Found
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CustomTabPanel>
                        }


                    </Box>
                </Grid>

                {/* <Grid xs={12} sm={12} md={12} lg={12} xl={2} order={!isLgUp ? 4 : 4}>
                    <Box sx={{ width: '100%' }}>
                        <Box >
                            <Tabs value={value7} onChange={handleChange7} aria-label="basic tabs example" className='tabs-trade'>
                                <Tab label="Asset Overview" {...a11yProps(0)} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value7} index={0}>
                            <div className="display-2 mt-10">
                                <div className="aval">
                                    Trading Account
                                </div>
                                <div className="display-1 usd">
                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="display-2 mt-10">
                                <div className="aval">
                                    USDT
                                </div>
                                <div className="display-1 usd">
                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="display-2 mt-10">
                                <div className="aval">
                                    BTC
                                </div>
                                <div className="display-1 usd">
                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>

                            <div className="display-2 mt-10">
                                <div className="aval">
                                    Funding Account
                                </div>
                                <div className="display-1 usd">
                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>

                            <div className="display-2 mt-10">
                                <div className="aval">
                                    BTC
                                </div>
                                <div className="display-1 usd">

                                    <div className="">
                                        0 USDT
                                    </div>
                                </div>
                            </div>
                            <div className="display-2 mt-20">
                                <div className="dep-trns-tbn ">
                                    <Button variant='contained'>Deposit </Button>
                                </div>
                                <div className="dep-trns-tbn ">
                                    <Button variant='contained'>Transfer </Button>
                                </div>
                            </div>

                        </CustomTabPanel>
                        <CustomTabPanel value={value6} index={1}>

                        </CustomTabPanel>

                    </Box>
                </Grid> */}
            </Grid>

            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="text-right">
                        <HighlightOffIcon onClick={handleClose} className='cursor' sx={{ fill: 'var(--clr-font)' }} />
                    </div>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className='font-clr'>

                    </Typography>
                    <div className="mt-20 font-clr">
                        {/* BTCUSDT */}
                        {/* {id?.split("_")?.join("/")} */}
                    </div>
                    <div className="mt-20" >
                        <div id="firstFilter" class="filter-switch">
                            <input id="option1" name="options" type="radio" value="LIMIT" onChange={((e) => { setPosSide(e.target.value) })} defaultChecked={posSide === 'LIMIT' && true} />
                            <label class="option" for="option1">Limit</label>
                            <input id="option2" name="options" type="radio" value="MARKET" onChange={((e) => { setPosSide(e.target.value) })} defaultChecked={posSide === 'MARKET' && true} />
                            <label class="option" for="option2">Market</label>
                            <span class="background"></span>
                        </div>

                    </div>
                    {posSide === 'LIMIT' ?
                        <div className='mt-20'>
                            <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    placeholder='Quantity'
                                    value={posData?.positionAmt}
                                    onChange={(e) => {
                                        setPosData(prevState => ({ ...prevState, positionAmt: e?.target?.value }))
                                    }}
                                    type="number"
                                />
                            </FormControl>
                            <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    placeholder='Price'
                                    value={posData?.entryPrice}
                                    onChange={(e) => {
                                        setPosData(prevState => ({
                                            ...prevState, entryPrice
                                                : e?.target?.value
                                        }))
                                    }}
                                    type="number"
                                />
                            </FormControl>
                        </div>
                        :
                        <div className='mt-20'>
                            <FormControl sx={{ width: '100%' }} variant="outlined" className="price-field">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment position="end" className='adroment'>{id?.split("_")[1]}</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    placeholder='Size'
                                    value={posData?.positionAmt}
                                    onChange={(e) => {
                                        setPosData(prevState => ({
                                            ...prevState, positionAmt
                                                : e?.target?.value
                                        }))
                                    }}
                                    type="number"
                                />
                            </FormControl>
                        </div>
                    }

                    <div className="cnfi mt-50">
                        <Button disabled={load} onClick={handleClosePosition}>
                            Confirm
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div >
    )
}

export default TradeHistory
