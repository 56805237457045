import { Grid } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import Header from '../Header/Header'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import darkarrow from "../../Img/dark-Layer.png";
import lightarrow from "../../Img/light-layer.png";
import { Link } from "react-router-dom";
import Support2 from '../Support/Support2'
import Stack from '@mui/material/Stack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { styled, alpha } from "@mui/material/styles";
import usercalls from "../../Axios";
import io from "socket.io-client";
import consts from '../../constant';
import axios from "axios";

import {
    TextField,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    '@media screen and (max-width: 600px)': {
        width: '90%',
    },
    bgcolor: 'transparent',
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});


function OrderDetail() {
    const lastMessageRef = useRef(null);
    const isReceived = useRef(false);
    const isMounted = useRef(false);
    const startSocket = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const path = usercalls();
    const usersId = localStorage.getItem('use');
    const data = location.state;
    // console.log(data, "StateData");
    // const bankData = data?.data?.paymentsMethods.find(item => item?.Type == "BANK");
    // var upiData = data?.data?.paymentsMethods.find(item => item?.Type == "UPI");
    // var gpayData = data?.data?.paymentsMethods.find(item => item?.Type == "GPAY");
    // console.log(upiData, "upiData");
    // console.log(data, "stateData");
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    var steps;
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewSource, setPreviewSource] = useState(null);
    const [order, setOrder] = useState({});
    const [imageurl, setImageUrl] = useState(null);
    const [imageurlErr, setImageUrlErr] = useState("");
    const token = localStorage.getItem('Rikosta')
    const [paymentMethod, setPaymentMethod] = useState([]);
    // console.log(socketInstance, "socketInstance");
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    //const socketInstance = io.connect(consts?.socketUrl, { path: "/socket" });
    var socketInstance = io.connect(consts?.socketUrl);

    // const handleImageUpload = async (event) => {
    //     const file = event.target.files[0];

    //     const formdata = new FormData();
    //     formdata.append("image", file)

    //     const url = `${consts.BackendUrl}/users/imageUpload`

    //     const { data } = await axios.post(url, formdata, {
    //       headers: {
    //         'Authorization': `${token}`,
    //       },
    //     });
    //     if (data.success) {
    //       const url = `${consts.BackendUrl}/users/UpdateUser`
    //       const payload = {
    //         profilePicture: data.result
    //       }
    //       const response = await path.postCall({ url, payload });
    //       const result = await response.json();
    //       console.log(result.result.name, 'result.result.name');

    //       if (result.success) {
    //         getProfile()
    //       }
    //     }
    //     else {
    //       console.log('error');
    //     }
    //   };

    const handleFileChange = async (event) => {
        setImageUrlErr("");
        const file = event.target.files[0];
        setSelectedFile(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewSource(reader.result);
        };
        reader.readAsDataURL(file);
        const formdata = new FormData();
        formdata.append("image", file);
        try {

            const url = `${consts.BackendUrl}/users/imageUpload`;

            const { data } = await axios.post(url, formdata, {
                headers: {
                    'Authorization': `${token}`,
                },
            });

            if (data?.success) {
                toast.success(data?.message);
                setImageUrl(data?.result);
            } else {
                toast.error(data?.message);
            }

        } catch (error) {
            console.log(error, "error uploading image");
        }

    };

    // const handleFileChange = (event) => {
    //     const file = event.target.files[0];
    //     setSelectedFile(file);

    //     const reader = new FileReader();
    //     reader.onloadend = () => {
    //         setPreviewSource(reader.result);
    //     };
    //     reader.readAsDataURL(file);
    // };

    const getOrder = async () => {

        try {

            const url = `${consts.BackendUrl}/p2p/getp2porder`;
            var payload = {
                txid: data
            };

            const response = await path.postCall({ url, payload });
            const result = await response.json();
            // console.log(result,"resultorder");

            if (result?.success) {
                setOrder(result?.result);
            }

        } catch (error) {
            console.log(error, "error gettin order detail")
        }

    };

    useEffect(() => {
        getOrder();
    }, [data]);

    const handleNotify = async () => {

        if (imageurl == null) {
            setImageUrlErr("Please Upload Image Proof");
        } else {

            try {

                const url = `${consts.BackendUrl}/p2p/notifyp2p`;
                var payload = {
                    _id: order._id,
                    image: imageurl
                };

                const response = await path.postCall({ url, payload });
                const result = await response.json();

                if (result?.success) {
                    getOrder();
                }
                //  else {
                //     toast.error(result?.message);
                // }

            } catch (error) {
                console.log(error, "error gettin order");
            }
        }

    };

    const releaseAsset = async (id) => {

        try {

            const url = `${consts.BackendUrl}/p2p/release`;
            var payload = {
                txid: id
            };

            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {
                toast.success(result?.message);
                setTimeout(() => {
                    navigate("/p2p");
                }, 1000);
            }
            // else {
            //     toast.error(result?.message);
            // }

        } catch (error) {
            console.log(error, "error release asset");
        }

    };

    const confirmpay = (
        <div className='makepay-main'>
            <h4>
                Confirm Order info
            </h4>
            <div className='display-1 time-details order_det'>
                <div>
                    Amount
                    <div className='order green'>
                        {/* 98INR */}
                        {order?.price ? order?.price : ""}
                    </div>
                </div>
                <div>
                    Price
                    <div className='order'>
                        {/* 98 INR */}
                        {order?.actualPrice ? order?.actualPrice : ""}
                    </div>
                </div>
                <div>
                    Receive Quantity
                    <div className='order'>
                        {/* 1 USDT */}
                        {`${order?.quantity ? order?.quantity : ""} USDT`}
                    </div>
                </div>
            </div>
        </div>

    )

    const makepay = (
        < div className='makepay-main' >
            {/* <h4>
                Make Payment
            </h4>
            <div>
                Transfer the funds to the seller’s account provided below.
            </div> */}
            <div className='make-pay-inner'>

                {console.log(order.buyer_id != usersId, " order.buyer_id != usersId")}
                {
                    order.buyer_id != usersId ?
                        order?.type == 'BUY' ? <>
                            <h4>
                                Make Payment
                            </h4>
                            <div>
                                Transfer the funds to the seller’s account provided below.
                            </div>
                            {
                                order?.paymentDetailes?.Type == "BANK" ?
                                    (<>
                                        <div className='display-1 added-list'>
                                            <div>
                                                <div className='paymeny-type-text main'>
                                                    Bank
                                                </div>
                                            </div>


                                        </div>
                                        <div>
                                            <div className='order-num'>
                                                Name:<span>{order?.paymentDetailes?.Name}</span>
                                            </div>
                                            <div className='order-num'>
                                                ACCOUNT NO:<span>{order?.paymentDetailes?.Account_number ? order?.paymentDetailes?.Account_number : ""}</span>
                                            </div>
                                            <div className='order-num'>
                                                IFSC CODE<span>{order?.paymentDetailes?.Ifsc_code ? order?.paymentDetailes?.Ifsc_code : ""}</span>
                                            </div>
                                        </div>
                                    </>
                                    ) : ""
                            }

                            {
                                order?.paymentDetailes?.Type == "UPI" ? (
                                    <>
                                        <div className='display-1 added-list'>
                                            <div>
                                                <div className='paymeny-type-text main'>
                                                    UPI
                                                </div>
                                            </div>


                                        </div>

                                        <div>
                                            <div className='order-num'>
                                                Name:<span>{order?.paymentDetailes?.Name}</span>

                                            </div>
                                            <div className='order-num'>
                                                UPI ID:<span>{order?.paymentDetailes?.Upi_id}</span>
                                            </div>

                                        </div>
                                    </>
                                ) : ""
                            }

                            {
                                order?.paymentDetailes?.Type == "GPAY" ? (
                                    <>
                                        <div className='display-1 added-list'>
                                            <div>
                                                <div className='paymeny-type-text main'>
                                                    GPAY
                                                </div>
                                            </div>


                                        </div>

                                        <div>
                                            <div className='order-num'>
                                                Name:<span>{order?.paymentDetailes?.Name}</span>

                                            </div>
                                            <div className='order-num'>
                                                Number :<span>{order?.paymentDetailes?.Upi_id}</span>
                                            </div>

                                        </div>
                                    </>
                                ) : ""
                            } </>
                            :
                            <>
                                {
                                    order?.availablePayment?.includes("BANK") ?
                                        (<>
                                            <div className='display-1 added-list'>
                                                <div>
                                                    <div className='paymeny-type-text main'>
                                                        Bank
                                                    </div>
                                                </div>


                                            </div>
                                            <div>
                                                <div className='order-num'>
                                                    Name:<span>{order?.bankDetailes?.Name}</span>
                                                </div>
                                                <div className='order-num'>
                                                    ACCOUNT NO:<span>{order?.bankDetailes?.Account_number ? order?.bankDetailes?.Account_number : ""}</span>
                                                </div>
                                                <div className='order-num'>
                                                    IFSC CODE<span>{order?.bankDetailes?.Ifsc_code ? order?.bankDetailes?.Ifsc_code : ""}</span>
                                                </div>
                                            </div>
                                        </>
                                        ) : ""
                                }

                                {
                                    order?.availablePayment?.includes("UPI") ? (
                                        <>
                                            <div className='display-1 added-list'>
                                                <div>
                                                    <div className='paymeny-type-text main'>
                                                        UPI
                                                    </div>
                                                </div>


                                            </div>

                                            <div>
                                                <div className='order-num'>
                                                    Name:<span>{order?.upiDetailes?.Name}</span>

                                                </div>
                                                <div className='order-num'>
                                                    UPI ID:<span>{order?.upiDetailes?.Upi_id}</span>
                                                </div>

                                            </div>
                                        </>
                                    ) : ""
                                }

                                {
                                    order?.availablePayment?.includes("GPAY") ? (
                                        <>
                                            <div className='display-1 added-list'>
                                                <div>
                                                    <div className='paymeny-type-text main'>
                                                        GPAY
                                                    </div>
                                                </div>


                                            </div>

                                            <div>
                                                <div className='order-num'>
                                                    Name:<span>{order?.gpayDetailes?.Name}</span>

                                                </div>
                                                <div className='order-num'>
                                                    Number :<span>{order?.gpayDetailes?.Upi_id}</span>
                                                </div>

                                            </div>
                                        </>
                                    ) : ""
                                }
                            </>
                        :

                        <>
                            {
                                order?.availablePayment?.includes("BANK") ?
                                    (<>
                                        <div className='display-1 added-list'>
                                            <div>
                                                <div className='paymeny-type-text main'>
                                                    Bank
                                                </div>
                                            </div>


                                        </div>
                                        <div>
                                            <div className='order-num'>
                                                Name:<span>{order?.bankDetailes?.Name}</span>
                                            </div>
                                            <div className='order-num'>
                                                ACCOUNT NO:<span>{order?.bankDetailes?.Account_number ? order?.bankDetailes?.Account_number : ""}</span>
                                            </div>
                                            <div className='order-num'>
                                                IFSC CODE<span>{order?.bankDetailes?.Ifsc_code ? order?.bankDetailes?.Ifsc_code : ""}</span>
                                            </div>
                                        </div>
                                    </>
                                    ) : ""
                            }

                            {
                                order?.availablePayment?.includes("UPI") ? (
                                    <>
                                        <div className='display-1 added-list'>
                                            <div>
                                                <div className='paymeny-type-text main'>
                                                    UPI
                                                </div>
                                            </div>


                                        </div>

                                        <div>
                                            <div className='order-num'>
                                                Name:<span>{order?.upiDetailes?.Name}</span>

                                            </div>
                                            <div className='order-num'>
                                                UPI ID:<span>{order?.upiDetailes?.Upi_id}</span>
                                            </div>

                                        </div>
                                    </>
                                ) : ""
                            }

                            {order?.type == "SELL" && order?.isNotified == false ?
                                order?.availablePayment?.includes("GPAY") ? (
                                    <>
                                        <div className='display-1 added-list'>
                                            <div>
                                                <div className='paymeny-type-text main'>
                                                    GPAY
                                                </div>
                                            </div>


                                        </div>

                                        <div>
                                            <div className='order-num'>
                                                Name:<span>{order?.gpayDetailes?.Name}</span>

                                            </div>
                                            <div className='order-num'>
                                                Number :<span>{order?.gpayDetailes?.Upi_id}</span>
                                            </div>

                                        </div>
                                    </>
                                ) : ""
                                : null
                            }
                        </>
                }

                {/* </div> */}
            </div>
            {order?.type == "SELL" && order?.isNotified == false && order?.user_id != usersId ? <div>
                <div className="inner-doc ticket-box up_load">
                    <div className="text-center">Upload Document</div>
                    <Button component="label" className="upload-detail">
                        <div className="uplad-btn">Upload</div>
                        {previewSource && (
                            <img src={previewSource} alt="Selected Image" />
                        )}
                        <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChange}
                        />
                    </Button>
                </div>
            </div>
                : order?.type == "BUY" && order?.isNotified == false && order?.buyer_id != usersId ?
                    <div>
                        <div className="inner-doc ticket-box up_load">
                            <div className="text-center">Upload Document</div>
                            <Button component="label" className="upload-detail">
                                <div className="uplad-btn">Upload</div>
                                {previewSource && (
                                    <img src={previewSource} alt="Selected Image" />
                                )}
                                <VisuallyHiddenInput
                                    type="file"
                                    onChange={handleFileChange}
                                />
                            </Button>
                        </div>
                    </div> :
                    null
            }

        </div >

        // </div >
    )


    const cnfpay = (
        <div className='makepay-main'>
            <h4>
                Confirm Payment
            </h4>
            <div>
                After transferring the funds, click on the "Transfered, Notify Seller" button.
            </div>
        </div>
    )

    const cnfpayImg = (
        <div>
            <div className='' >
                <img src={order?.image} alt='' style={{ width: '100px', height: '100px' }} />
            </div>
            <div onClick={handleOpen} className='cursor'>
                View
            </div>
        </div>
    )

    console.log(order?.type == "BUY", "errrrr",);

    order?.type == "BUY" ? steps = usersId != order?.user_id && order?.isNotified == false ? [confirmpay, cnfpay] : order?.user_id && order?.isNotified == true ? [confirmpay, makepay, cnfpayImg, cnfpay] : [confirmpay, makepay, cnfpay] :
        steps = usersId == order?.buyer_id && order?.isNotified == false ? [confirmpay, makepay, cnfpay] : [confirmpay, cnfpayImg, cnfpay];


    // steps = order?.type == "BUY" && usersId != order?.user_id ? [confirmpay, cnfpay] : [confirmpay, makepay, cnfpay];
    // steps = order?.type == "SELL" && usersId == order?.buyer_id && order?.isNotified == false ? [confirmpay, makepay, cnfpay] : [confirmpay, cnfpayImg, cnfpay];

    const [perChat, setPerChat] = useState([]);
    const [activeStep, setActiveStep] = React.useState(3);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };



    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [updated, setUpdated] = useState('');
    const [message, setMessage] = useState([]);
    const [movileView, setMovileView] = useState(false)

    const handleSend = () => {
        if (input.trim()) {
            setMessages([...messages, input.trim()]);
            setInput("");
        }
    };

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };


    const handleChangeSend = (event) => {
        setUpdated(event.target.value);

    };

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSend();
        }
    };


    const handleClickSend = (e) => {
        // 👇 "message" stores input field value

        if (updated?.trim() !== '') {
            setMessage([...message, updated])
            setUpdated('')
        }
    };

    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleChatData = (val) => {
        setPerChat(val)
        if (!isCollapsed && matches) {
            handleToggle()
        } else {
            setMovileView(true)
        }

    }

    // const [timeLeft, setTimeLeft] = useState(900); // 15 minutes in seconds

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         if (timeLeft > 0) {
    //             setTimeLeft(timeLeft - 1);
    //         } else {
    //             clearInterval(intervalId);
    //         }
    //     }, 1000);

    //     return () => clearInterval(intervalId);
    // }, [timeLeft]);


    // const minutes = Math.floor(timeLeft / 60);
    // const seconds = timeLeft % 60;


    const handleMessageSent = async () => {
        try {

            socketInstance.emit('join', {
                room: order?.txId
            })

            socketInstance.emit('send', {
                room: order?.txId,
                message: updated,
                seen: false,
            })

            const payload = {
                id: chatId,
                message: updated
            }

            const url = `${consts.BackendUrl}/p2p/updatechat`;
            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {
                var message = {
                    msg: updated,
                    seen: false,
                    from: usersId,
                    time: new Date()
                };
                setUpdated('');
                // setP2pChats( (pre) => [...pre, message] );
            }


        } catch (error) {
            console.log(error, "error sending message");
        }

    };


    useEffect(() => {

        socketInstance.on('connect', () => {
            // console.log('Connected to server');
        });

        // socketInstance.on('message', (data) => {
        //   console.log(`Received message: ${data}`);
        // });

        socketInstance.on('connect_error', (error) => {
            console.log('Connection error:', error);
        });

        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        }
    }, []);


    useEffect(() => {
        if (order?.txId != undefined) {
            // Join the room
            socketInstance.emit('join', { room: order?.txId });

            // Event listener for receiving messages
            const handleReceive = async (data) => {
                var message = {
                    msg: data?.message,
                    seen: false,
                    from: usersId,
                    time: new Date()
                };
                setP2pChats((pre) => [...pre, message]);
                // setTimeout(async () => {
                //     await msgSeen(order?.txId);
                // }, [2000])
            };

            socketInstance.on("receive", handleReceive);

            isMounted.current = true;

            // Cleanup listener when component unmounts or room_id changes
            return () => {
                socketInstance.off("receive", handleReceive);
                isMounted.current = false;
            };
        }
    }, [order]);


    const [p2pChats, setP2pChats] = useState([]);
    const [chatId, setChatId] = useState("");

    const getTickets = async () => {

        try {

            var payload = {
                // _id: "6756bc165d850b5970f84ded"
                txId: order?.txId
            };
            const url = `${consts.BackendUrl}/p2p/getchats`;
            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {
                setP2pChats(result?.result?.Message);
                setChatId(result?.result?._id);
            }
            // else {
            //     toast.error(result?.message);
            // }

        } catch (error) {
            console.log(error, "error getting messages");
        }

    };

    useEffect(() => {
        getTickets();
    }, [order]);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [p2pChats]);

    const handleCancellP2p = async () => {

        try {

            var payload = {
                txId: order?.txId
            };
            const url = `${consts.BackendUrl}/p2p/cancelp2p`;
            const response = await path.postCall({ url, payload });
            const result = await response.json();

            if (result?.success) {
                toast.success(result?.message);

                setTimeout( () => {
                    navigate("/p2p");
                }, 1000);

            } else {
                toast.error(result?.message);
            }

        } catch (error) {
            console.log(error, "Error Cancelling P2p Order");
        }

    };

    return (
        <div className='order-details'>
            <Grid container className='justi-center'>

                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} className='sti_ck' >
                    <div class="ivon-back">
                        <Link to="/p2p" className="light-mode-img">
                            <img src={darkarrow} />
                        </Link>
                        <Link to="/p2p" className="dark-mode-img">
                            <img src={lightarrow} />
                        </Link>
                    </div>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>

                    <div className='display-2 order-details-mian'>
                        <div className='order-cancel-text'>
                            Order Confirmed
                            {/* <div className='time_r' > Pay the seller within<span> {minutes} </span>:<span>{seconds.toString().padStart(2, '0')} </span>   </div> */}
                        </div>
                        <div>
                            <div className='order-num'>
                                {/* Order Number:<span>TXNIAFH879A4D6A</span> */}
                                Order Number:<span>{order?.txId}</span>
                            </div>

                            <div className='order-num'>
                                {/* Time Created: <span>2024-05-16</span> */}
                                {/* Time Created: <span>{data?.data?.createdAt.split("T")[0]}</span> */}
                            </div>
                        </div>
                    </div>

                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={10}>
                    <Grid container className='justi-center'>
                        <Grid xs={12} sm={12} md={12} lg={7} xl={8}>
                            <div>
                                <Box sx={{ width: '100%' }}>
                                    <Stepper activeStep={activeStep} orientation="vertical" className="stepper-line">
                                        {steps.map((label, index) => {
                                            const stepProps = {};
                                            const labelProps = {};
                                            if (isStepOptional(index)) {
                                                labelProps.optional = (
                                                    <Typography variant="caption"></Typography>
                                                );
                                            }
                                            if (isStepSkipped(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                    {activeStep === steps.length ? (
                                        <React.Fragment>
                                            {/* <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box> */}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                {isStepOptional(activeStep) && (
                                                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                        Skip
                                                    </Button>
                                                )}

                                                {/* <Button onClick={handleNext}>
                                                    {activeStep !== steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button> */}
                                            </Box>
                                        </React.Fragment>
                                    )}
                                </Box>
                            </div>

                            <div>{imageurlErr != "" ? <p style={{ color: "red", textAlign: "center", marginTop: "35px" }}>{imageurlErr}</p> : null}</div>
                            {/* {console.log(order, "order?.type", usersId)} */}
                            {order?.type == "BUY" ? <>
                                {order?.status == "completed" ? null :
                                    order?.isNotified == false && order?.buyer_id != usersId ?
                                        <div className='transfer-notify'>
                                            <Button onClick={handleNotify}>Transferred, notify seller</Button>
                                            <Button className='bg-red' onClick={handleCancellP2p}>Cancel</Button>
                                        </div>
                                        : order?.isNotified == true && order?.buyer_id == usersId && order?.status != "completed" ?
                                            <div className='transfer-notify'>
                                                <Button onClick={() => { releaseAsset(order?.txId) }}>Release Asset</Button>
                                            </div> :
                                            order?.isNotified == true ?

                                                <div className='transfer-notify'>
                                                    <Button > Raise/Dispute </Button>
                                                    <Button className='bg-red' onClick={() => navigate("/p2p")}> Cancel </Button>
                                                </div> : null

                                }
                            </> : <>
                                {order?.status == "completed" ? null :
                                    order?.isNotified == false ?
                                        <div className='transfer-notify'>
                                            <Button onClick={() => { handleNotify() }}>Transferred, notify seller</Button>
                                            <Button className='bg-red' onClick={handleCancellP2p}>Cancel</Button>
                                        </div> : order?.isNotified == true && order?.user_id == usersId ?
                                            <div className='transfer-notify'>
                                                <Button onClick={() => { releaseAsset(order?.txId) }}>Release Asset</Button>
                                            </div> :
                                            <div className='transfer-notify'>
                                                <Button > Raise/Dispute </Button>
                                                <Button className='bg-red' onClick={() => navigate("/p2p")}> Cancel </Button>
                                            </div>
                                }
                            </>
                            }
                        </Grid>
                        <Grid xs={12} sm={12} md={12} lg={5} xl={4}>
                            {/* <div className="ticket-box">
                                <div className="ticket display-1">
                                    <div className="ticket-head">
                                        <p>Chat</p>
                                    </div>
                                </div>
                                <div className="colab-text-input">
                                    <div className='chat-space'>
                                        <div className="message-send-box">
                                            <div className="profile-img">
                                                <Avatar src="/broken-image.jpg" />
                                            </div>
                                            <span>Test</span>
                                        </div>
                                        <div className="message-send-box">

                                            <List sx={{ maxHeight: 300, overflowY: "auto" }}>
                                                {messages.map((message, index) => (
                                                    <ListItem key={index}>
                                                        <div className="profile-img">
                                                            <Avatar src="/broken-image.jpg" />
                                                        </div>
                                                        <ListItemText primary={message} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    </div>
                                    <div className="message-sent">

                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            value={input}
                                            onChange={handleInputChange}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    handleSend();
                                                }
                                            }}
                                        />
                                        <div className="colab-send">
                                            <SendIcon onClick={handleSend} />
                                        </div>

                                    </div>
                                </div>
                            </div> */}
                            <div className="msglist">
                                <div className="colab-avatar-cont vr">
                                    <Stack direction="row" spacing={-0.5}>
                                        {/* {<div div className="cursor">
                        {!isCollapsed ? <ArrowBackIosIcon sx={{ fontSize: '25px !important', fill: '#7a7a7a' }}
                            onClick={(() => { movileView ? setMovileView(false) : handleToggle() })} /> : <ArrowForwardIosIcon sx={{ fontSize: '25px !important', fill: '#7a7a7a' }} onClick={handleToggle} />}
                    </div>} */}
                                        {/* <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={artist1} />
                    <Avatar sx={{ width: 30, height: 30 }} alt="Travis Howard" src={artist2} />
                    <Avatar sx={{ width: 30, height: 30 }} alt="Cindy Baker" src={artist3} /> */}
                                    </Stack>
                                </div>
                                <div className="colab-chat-area msg">


                                    {/* <div className="chat-area-inner-text vr">
                                        <div>
                                            <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
                                        </div>
                                        <div className="collab-send-text-main">
                                            <div className="username">{perChat.name}(dev)</div>
                                            <div className="txt1">{perChat.noftify}</div>
                                            <div className="time">11:45am</div>
                                        </div>
                                    </div> */}
                                    {/* {message.map((row, ind) => { */}
                                    {/* {p2pChats?.map((row, ind) => {
                                        return (

                                            <div className={ row?.from == "672c5e237727aa0027e55c83" ? "chat-area-inner-text user vr" : "chat-area-inner-text vr"} key={ind}>
                                                <div>
                                                    <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={perChat.img} />
                                                </div>
                                                <div className={row?.from == "672c5e237727aa0027e55c83" ? "collab-send-text-main2 user" : "collab-send-text-main"}>
                                                    <div className="username">Me(dev)</div>
                                                    <div className="txt1">{row?.msg}</div>
                                                    <div className="time">11:4555am</div>
                                                </div>
                                            </div>
                                        )
                                    })} */}

                                    {p2pChats?.map((row, ind) => {
                                        const isUser = row?.from == usersId;
                                        return (
                                            <div
                                                className={isUser ? "chat-area-inner-text user vr" : "chat-area-inner-text vr"}
                                                key={ind}
                                                ref={ind == p2pChats.length - 1 ? lastMessageRef : null} // Set ref on the last message
                                            >
                                                <div>
                                                    <Avatar sx={{ width: 30, height: 30 }} alt="Remy Sharp" src={row?.img} />
                                                </div>
                                                <div className={isUser ? "collab-send-text-main2 user" : "collab-send-text-main"}>
                                                    {/* <div className="username">{isUser ? "You" : row?.username}</div> */}
                                                    <div className="username">{isUser ? "You" : "Buyer"}</div>
                                                    <div className="txt1">{row?.msg}</div>
                                                    <div className="time">{new Date(row?.time).toLocaleTimeString()}</div>
                                                </div>
                                            </div>
                                        );
                                    })}

                                </div>



                                <div class="messageBox">
                                    <div class="fileUploadWrapper">
                                        <label for="file">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 337 337">
                                                <circle
                                                    stroke-width="20"
                                                    stroke="#6c6c6c"
                                                    fill="none"
                                                    r="158.5"
                                                    cy="168.5"
                                                    cx="168.5"
                                                ></circle>
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-width="25"
                                                    stroke="#6c6c6c"
                                                    d="M167.759 79V259"
                                                ></path>
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-width="25"
                                                    stroke="#6c6c6c"
                                                    d="M79 167.138H259"
                                                ></path>
                                            </svg>
                                            <span class="tooltip">Add an image</span>
                                        </label>
                                        <input type="file" id="file" name="file" />
                                    </div>
                                    <input required="" placeholder="Message..." type="text" id="messageInput"
                                        value={updated}
                                        onChange={handleChangeSend}
                                        onKeyDown={handleEnterKeyPress}
                                    />
                                    {/* <button id="sendButton" onClick={handleClickSend}> */}
                                    <button id="sendButton" onClick={handleMessageSent}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 664 663">
                                            <path
                                                fill="none"
                                                d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                                            ></path>
                                            <path
                                                stroke-linejoin="round"
                                                stroke-linecap="round"
                                                stroke-width="33.67"
                                                stroke="#6c6c6c"
                                                d="M646.293 331.888L17.7538 17.6187L155.245 331.888M646.293 331.888L17.753 646.157L155.245 331.888M646.293 331.888L318.735 330.228L155.245 331.888"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>

                            </div>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <img src={order?.image} alt='' style={{ width: '100%', height: '100%' }} />
                </Box>
            </Modal>
        </div>
    )
}

export default OrderDetail
