import React, { useState, useEffect } from 'react'
import { Button, Grid } from '@mui/material'
import Header from '../Header/Header'
import Footer from '../Footer/footer'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import './Copytrade.css'
import Box from '@mui/material/Box';
import { Bgeye, Fire, Profile } from '../../SvgCollection';
import { Link } from 'react-router-dom';
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import kcs from '../../Img/coins/kcs.png'
import scr from '../../Img/coins/scr.png'
import cat from '../../Img/coins/cat.png'
import Avatar from '@mui/material/Avatar';
// import searchs from '../../Img/search.svg'

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import prf from '../../Img/prf.png';
import card_chart from '../../Img/card_chart.png';
import card_icon from '../../Img/card_icon.png';
import consts from "../../constant";
import usercalls from "../../Axios";
import { toast, ToastContainer } from "react-toastify";



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Copytrade() {
    const usersId = localStorage.getItem('use');
    const isMaster = localStorage.getItem('master');
    const path = usercalls()
    const [value, setValue] = useState(0);
    const [show, setShow] = useState(true);
    const [masters, setMasters] = useState([]);
    const [mastersCopy, setMastersCopy] = useState([]);
    const [load, setLoad] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const handleMasterTraderRequest = async () => {

        try {

            const url = `${consts.BackendUrl}/binanceTrade/becomemaster`
            const response = await path.getCallWithToken({ url });
            const results = await response.json();
            if (results?.success) {
                toast.success(results?.message);
            } else {
                toast.error(results?.message);
            }

        } catch (error) {
            console.log(error, "Error Sending Master Request To Admin");
        }

    };

    const getAllMasterTraders = async () => {

        try {

            const url = `${consts.BackendUrl}/binanceTrade/allmaster`
            const response = await path.getCallWithToken({ url });
            const results = await response.json();
            if (results?.success) {
                setMasters(results?.result)
                setMastersCopy(results?.result)
            }

        } catch (error) {
            console.log(error, "Error Getting All Master Traders");
        }

    };

    const copyMaster = async (type, id) => {

        try {
            setLoad(true);
            var payload = {
                type: type,
                id: id
            };
            const url = `${consts.BackendUrl}/binanceTrade/followmaster`
            const response = await path.postCall({ url, payload });
            const results = await response.json();
            if (results?.success) {
                toast.success(results?.message);
                getAllMasterTraders();
            } else {
                toast.error(results?.message);
            }

        } catch (error) {
            console.log(error, "Error Copying Master");
        } finally{
            setLoad(false);
        }

    };

    const handleMasterSearch = async (val) => {
        try {
            if (val == "") {
                setMasters(mastersCopy);
            } else {
                var match = [];
                const searchValue = val.toLowerCase();
                for (let i = 0; i < mastersCopy.length; i++) {
                    const ele = mastersCopy[i];

                    if (ele?.name?.toLowerCase().includes(searchValue)) {
                        match.push(ele);
                    }
                }
                setMasters(match);
            }

        } catch (error) {
            console.error("Error during master search:", error);
        }
    };

    useEffect(() => {
        getAllMasterTraders();
    }, []);

    return (
        <div className='copytrade'>
            <ToastContainer />
            <Grid container sx={{ justifyContent: 'center' }}>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Header />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container sx={{ justifyContent: 'center' }} className='copytrade-banner'>
                        <Grid xs={12} sm={12} md={12} lg={10} xl={9}>
                            <div className="padding-10">
                                <div className="display-2 " style={{ flexDirection: !matches && 'column', gap: "20px" }}>
                                    <div className="copy-headung">
                                        Copy Trading Pro
                                    </div>
                                    {
                                        isMaster == "false" ?
                                            <div className="copy-h-tbn display-1 cursor" onClick={handleMasterTraderRequest}>

                                                <Profile />
                                                {/* Become a top trader */}
                                                Request As Master Trader
                                            </div> :
                                            <div className="copy-h-tbn display-1 cursor">

                                                <Profile />
                                                {/* Become a top trader */}
                                                Master Trader
                                            </div>
                                    }
                                    {/* <div className="copy-h-tbn display-1 cursor" onClick={handleMasterTraderRequest}>

                                         <Profile />
                                        Request As Master Trader
                                 </div> */}
                                </div>
                                <div className="display-2 copy_ban" style={{ justifyContent: !matches && 'center', flexWrap: "wrap", gap: "10px" }}>
                                    <div className="copy-h-para mt-20">
                                        Copy expert strategies and capture market signals
                                        <div className="mt-20 display-1 m_center">
                                            <Avatar
                                                alt="Remy Sharp"
                                                src="/static/images/avatar/1.jpg"
                                                sx={{ width: 30, height: 30 }}
                                            />
                                            <div className="lgIn">Login</div>
                                            {!show ? (
                                                <VisibilityOffIcon
                                                    onClick={() => {
                                                        setShow(!show);
                                                    }}
                                                    className="cursor"
                                                />
                                            ) : (
                                                <VisibilityIcon
                                                    onClick={() => {
                                                        setShow(!show);
                                                    }}
                                                    className="cursor"
                                                />
                                            )}
                                        </div>
                                        <div className="mt-20 display-2">
                                            <div className="">
                                                {show ? '84852$' : "*****"}
                                                <div className="mt-10 copy-sub-p">
                                                    Copy trading balance (USDT)
                                                </div>
                                            </div>
                                            <div className="">
                                                {show ? '14895$' : "*****"}
                                                <div className="mt-10 copy-sub-p">
                                                    Copy trading net profit (USDT)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="copy_box">
                                        <div>Super upgrade for the Copy Trading Proevent</div>
                                        <div className='big' >Full compensation for your 1st liquidation!</div>
                                        <div>1/1</div>
                                    </div>
                                </div>
                            </div>


                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={10} xl={9}>
                    <Box sx={{ width: '100%' }}>
                        <Box class="cP_flx" >
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='copytrade-tab'>
                                {/* <Tab label={<div className="display-1">
                                    <Fire />Top traders
                                </div>
                                } {...a11yProps(0)} /> */}
                                <Tab label="All traders" {...a11yProps(0)} />

                            </Tabs>
                            <div>
                                <div className="input_grp">
                                    <div className="input_txt left cursor-pointer">
                                        {/* <img src={searchs} alt="img" className="invert" /> */}
                                        <svg width="16" height="15" viewBox="0 0 16 15" className="invert" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.43747 0.20816C3.72085 0.20816 0.70813 3.22088 0.70813 6.9375C0.70813 10.6541 3.72085 13.6668 7.43747 13.6668C9.04025 13.6668 10.5123 13.1064 11.6683 12.1708L14.2704 14.7729C14.4033 14.9058 14.5835 14.9804 14.7714 14.9804C14.9593 14.9804 15.1395 14.9058 15.2723 14.7729C15.4052 14.64 15.4799 14.4598 15.4799 14.2719C15.4799 14.084 15.4052 13.9038 15.2723 13.771L12.6703 11.1689C13.6408 9.97244 14.1693 8.47808 14.1668 6.9375C14.1668 3.22088 11.1541 0.20816 7.43747 0.20816ZM2.12497 6.9375C2.12497 4.00341 4.50338 1.625 7.43747 1.625C10.3716 1.625 12.75 4.00341 12.75 6.9375C12.75 9.8716 10.3716 12.25 7.43747 12.25C4.50338 12.25 2.12497 9.8716 2.12497 6.9375Z" fill="#F3F3F3" />
                                        </svg>
                                    </div>
                                    <input type="text" placeholder={`${"Trader's Name"}`} onChange={(e) => { handleMasterSearch(e?.target?.value) }} />
                                </div>
                            </div>
                        </Box>
                        {/* <CustomTabPanel value={value} index={0}>
                            <div className="display-2">
                                <div className="cpy-h">
                                    Highest copy trading PnL (3W)
                                </div>
                            </div>
                            <section className="card_box">
                                <div className="contain-width" style={{ padding: "0 !important" }}>
                                    <Grid container spacing={2} sx={{ justifyContent: "space-between" }} >
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box cursor">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box cursor">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                            <div className="display-2">
                                <div className="cpy-h">
                                    Highest total PnL (3W)
                                </div>
                            </div>
                            <section className="card_box">
                                <div className="contain-width" style={{ padding: "0 !important" }}>
                                    <Grid container spacing={2} sx={{ justifyContent: "space-between" }} >
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                            <div className="display-2">
                                <div className="cpy-h">
                                    Highest win rate (3W)
                                </div>
                            </div>
                            <section className="card_box">
                                <div className="contain-width" style={{ padding: "0 !important" }}>
                                    <Grid container spacing={2} sx={{ justifyContent: "space-between" }} >
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                        </CustomTabPanel> */}
                        <CustomTabPanel value={value} index={0}>
                            <div className="display-2">
                                <div className="cpy-h">
                                    All Master Traders
                                </div>
                            </div>
                            <section className="card_box">
                                <div className="contain-width" style={{ padding: "0 !important" }}>
                                    <Grid container spacing={2} sx={{ justifyContent: "flex-start" }} >
                                        {

                                            masters.length > 0 ? masters.map((row, index) => (

                                                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} key={index}>
                                                    <div className="box cursor">
                                                        <div className="h_flx">
                                                            <div className="flx" >
                                                                <img style={{ height: "60px", width: "60px" }} src={row?.profilePicture != "" ? row?.profilePicture : prf} alt="img" />
                                                                <div class="w-100">
                                                                    <h5>{row?.name}</h5>
                                                                    {/* <span>Too busy making money to …</span> */}
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                                <div>8 / <div className="grey">100</div></div>
                                                            </div>
                                                        </div>
                                                        <div className="flx">
                                                            <div>
                                                                <div className="green">+6.64%</div>
                                                                <span>ROI</span>
                                                            </div>
                                                            <div className="days">30 days</div>
                                                        </div>
                                                        <img src={card_chart} alt="img" />
                                                        <div className="f_box">
                                                            <div>
                                                                <div>Total PnL (3W)</div>
                                                                <div>$356.54</div>
                                                            </div>
                                                            <div>
                                                                <div>Copy trading PnL (3W)</div>
                                                                <div>$29550.81</div>
                                                            </div>
                                                            <div>
                                                                <div>Win rate (3W)</div>
                                                                <div>65.80%</div>
                                                            </div>
                                                            <div>
                                                                <div className="coins" >
                                                                    <img src={kcs} alt="img" />
                                                                    <img src={scr} alt="img" />
                                                                    <img src={cat} alt="img" />
                                                                </div>
                                                                <div>
                                                                    <img src={card_icon} alt="img" className="icon" />10%</div>
                                                            </div>
                                                        </div>
                                                        {/* <Link to={"/future-copy"} className="copy_btn" >Copy</Link> */}
                                                        {
                                                            row?.users_id.includes(usersId) ?
                                                                <Button className="copy_btn" disabled={load} onClick={() => { copyMaster("unsub", row?._id) }}>Unsubscribe</Button> :
                                                                <Button className="copy_btn" disabled={load} onClick={() => { copyMaster("sub", row?._id) }}>Copy</Button>
                                                        }
                                                    </div>
                                                </Grid>

                                            )) :

                                                null

                                        }
                                        {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box cursor">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid> */}
                                        {/* <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box cursor">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4} >
                                            <div className="box">
                                                <div className="h_flx">
                                                    <div className="flx" >
                                                        <img src={prf} alt="img" />
                                                        <div class="w-100">
                                                            <h5>Xiao Hai</h5>
                                                            <span>Too busy making money to …</span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.1659 8.74848L7.8859 7.86032C7.8859 7.86032 7.4505 7.69148 7.28222 7.52628C7.17386 7.41876 7.18142 7.16816 7.27746 6.84616C7.3735 6.52444 8.54194 5.2174 8.54194 4.02992C8.54194 2.19676 7.3357 0.709961 5.84918 0.709961C4.36238 0.709961 3.15754 2.19676 3.15754 4.02992C3.15754 5.16756 4.19914 6.52332 4.32738 6.76888C4.4559 7.01584 4.42482 7.3916 4.32374 7.50472C4.17142 7.6758 3.61394 7.8466 3.61394 7.8466L1.55846 8.7622C1.07826 8.93413 0.70166 9.38683 0.70166 9.89873V10.1406C0.70166 10.6981 1.15442 11.0397 1.71078 11.0397H10.0114C10.5677 11.0397 11.0213 10.6981 11.0213 10.1406V9.89873C11.0227 9.38663 10.6461 8.92013 10.1659 8.74848Z" fill="#D1D1D2" /></svg>
                                                        <div>8 / <div className="grey">100</div></div>
                                                    </div>
                                                </div>
                                                <div className="flx">
                                                    <div>
                                                        <div className="green">+6.64%</div>
                                                        <span>ROI</span>
                                                    </div>
                                                    <div className="days">30 days</div>
                                                </div>
                                                <img src={card_chart} alt="img" />
                                                <div className="f_box">
                                                    <div>
                                                        <div>Total PnL (3W)</div>
                                                        <div>$356.54</div>
                                                    </div>
                                                    <div>
                                                        <div>Copy trading PnL (3W)</div>
                                                        <div>$29550.81</div>
                                                    </div>
                                                    <div>
                                                        <div>Win rate (3W)</div>
                                                        <div>65.80%</div>
                                                    </div>
                                                    <div>
                                                        <div className="coins" >
                                                            <img src={kcs} alt="img" />
                                                            <img src={scr} alt="img" />
                                                            <img src={cat} alt="img" />
                                                        </div>
                                                        <div>
                                                            <img src={card_icon} alt="img" className="icon" />10%</div>
                                                    </div>
                                                </div>
                                                <Link to={"/future-copy"} className="copy_btn" >Copy</Link>
                                            </div>
                                        </Grid> */}
                                    </Grid>
                                </div>
                            </section>
                        </CustomTabPanel>

                    </Box>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Footer />
                </Grid>
            </Grid >
        </div >
    )
}

export default Copytrade
